import { addPropertyControls, ControlType } from "framer"
import { useEffect, useContext, useRef, useState } from "react"
import { motion } from "framer-motion"
import {
    FormIcon,
    iconPropertyControls,
    useFormStore,
} from "https://framer.com/m/SharedFormCode-HQOZ.js@TqcitBC3zIme9QoXbJuf"

const NO_REQUIRED_TYPES = ["checkbox", "consent", "slider"]
const ACCENT_COLOR_TYPES = ["checkbox", "consent", "radio", "multiSelect"]
const OPTIONS_TYPES = ["dropdown", "radio", "multiSelect"]
const TEXT_PLACEHOLDER_TYPES = ["input", "textArea"]
const PLACEHOLDER_FONT_COLOR_TYPES = [
    "input",
    "textArea",
    "number",
    "email",
    "phoneNumber",
]
const GAP_TYPES = ["checkbox", "consent", "slider", "nps", "phoneNumber"]
const GAP_HV_TYPES = ["radio", "multiSelect"]
const MIN_MAX_STEP_TYPES = ["number", "slider"]

const DROPDOWN_ICON_HEIGHT = 12
const DROPDOWN_NONE_SELECTED_VALUE = "[{(None Selected)}]"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function FormField(props) {
    const { type, name, options, gap, border, icon, invalidStyle } = props

    const elementId = "a" + Math.floor(Math.random() * 10000000).toString()

    const required = NO_REQUIRED_TYPES.includes(type) ? true : props.required
    const iconOnLeft = icon?.position == "left"

    const pt = props.paddingIsMixed ? props.paddingTop : props.padding
    const pl = props.paddingIsMixed ? props.paddingLeft : props.padding
    const pb = props.paddingIsMixed ? props.paddingBottom : props.padding
    const pr = props.paddingIsMixed ? props.paddingRight : props.padding
    const plIcon = pl + (icon && iconOnLeft ? icon.size + icon.gap : 0)
    const prIcon = pr + (icon && !iconOnLeft ? icon.size + icon.gap : 0)

    const minHeight = icon ? pt + icon.size + pb : undefined

    const [formState, setFormState] = useFormStore()
    const value = formState[name]?.value

    const [invalid, setInvalid] = useState(false)
    const invalidRef = useRef(invalid)
    const ref = useRef(null)

    function updateField(key, value) {
        setFormState((prev) => ({
            [name]: {
                ...prev[name],
                [key]: value,
            },
        }))
    }

    function invalidate() {
        invalidRef.current = true
        setInvalid(true)
    }

    function revalidate() {
        invalidRef.current = false
        setInvalid(false)
    }

    function isValid(value) {
        if (invalidRef.current) {
            return false
        }

        let valid = true

        switch (type) {
            case "input":
            case "textArea":
            case "email":
            case "phoneNumber":
                valid = value && value.length > 0
                break
            case "multiSelect":
                valid = Array.isArray(value) && value.length > 0
                break
            case "consent":
                valid = value == "on"
                break
            default:
                valid = value != null
                break
        }

        if (!valid) {
            invalidate()
        }

        return valid
    }

    useEffect(() => {
        let value = null
        switch (type) {
            case "input":
            case "textArea":
            case "email":
            case "phoneNumber":
                value = ""
                break
            case "checkbox":
                value = "off"
                break
            case "dropdown":
                value = options.includes(props.dropdownDefaultValue)
                    ? props.dropdownDefaultValue
                    : null
                break
            case "multiSelect":
                value = []
                break
            case "slider":
                value = props.sliderDefaultValue
                break
        }

        setFormState((prev) => {
            console.log("Adding field to form:", name)
            return {
                [name]: {
                    ref,
                    value: prev[name] ? prev[name].value : value,
                    defaultValue: value,
                    name,
                    required,
                    visible: true,
                    isValid,
                },
            }
        })

        return () => {
            updateField("visible", false)
        }
    }, [])

    const borderRadius = props.radiusIsMixed
        ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
        : `${props.radius}px`
    const style = {
        flex: 1,
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        padding: `${pt}px ${prIcon}px ${pb}px ${plIcon}px`,
        ...props.font,
        ...props.style,
    }

    function onChangeEventTargetValue(event) {
        updateField("value", event.target.value)
    }

    const elements = []

    switch (type) {
        case "input":
            elements.push(
                <input
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={style}
                    placeholder={props.textPlaceholder}
                />
            )
            break
        case "checkbox":
        case "consent":
            elements.push(
                <label
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: gap,
                        cursor: "pointer",
                        ...style,
                    }}
                >
                    <input
                        type="checkbox"
                        name={name}
                        checked={value === "on"}
                        onChange={(event) => {
                            updateField(
                                "value",
                                event.target.checked
                                    ? "on"
                                    : type == "checkbox"
                                    ? "off"
                                    : null
                            )
                        }}
                        onFocus={revalidate}
                        style={{
                            margin: 0,
                            cursor: "pointer",
                        }}
                    />
                    {type == "checkbox"
                        ? props.checkboxText
                        : props.consentText}
                </label>
            )
            break
        case "dropdown":
            elements.push(
                <div style={{ position: "relative", ...props.style }}>
                    <select
                        value={!value ? DROPDOWN_NONE_SELECTED_VALUE : value}
                        onChange={(event) => {
                            updateField(
                                "value",
                                event.target.value ==
                                    DROPDOWN_NONE_SELECTED_VALUE
                                    ? null
                                    : event.target.value
                            )
                        }}
                        onFocus={revalidate}
                        style={{
                            appearance: "none",
                            height: "100%",
                            cursor: "pointer",
                            ...style,
                        }}
                    >
                        {!options.includes(props.dropdownDefaultValue) && (
                            <option
                                value={DROPDOWN_NONE_SELECTED_VALUE}
                                disabled
                            >
                                {props.dropdownNoneSelectedText}
                            </option>
                        )}
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={DROPDOWN_ICON_HEIGHT}
                        height={DROPDOWN_ICON_HEIGHT}
                        viewBox="0 0 18 18"
                        fill="none"
                        stroke-width="2"
                        stroke={props.fontColor}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        style={{
                            display: "block",
                            position: "absolute",
                            right: icon && !iconOnLeft ? 0 : pr,
                            top: `calc(50% - ${DROPDOWN_ICON_HEIGHT / 2}px)`,
                            pointerEvents: "none",
                        }}
                    >
                        <path d="M2 5.5L9 12.5L16 5.5" />
                    </svg>
                </div>
            )
            break
        case "textArea":
            elements.push(
                <textarea
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={{
                        display: "block",
                        height: props.resizeable
                            ? props.textAreaDefaultHeight
                            : 100,
                        minHeight: minHeight,
                        resize: props.resizeable ? "vertical" : "none",
                        ...style,
                    }}
                    placeholder={props.textPlaceholder}
                />
            )
            break
        case "multiSelect":
            const multiSelectOnChange = (event) => {
                const multiSelectValue = event.target.checked
                    ? [...(value || []), event.target.name]
                    : (value || []).filter((v) => v !== event.target.name)
                updateField("value", multiSelectValue)
            }

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: props.gapV,
                        ...style,
                    }}
                >
                    {props.options.map((option, index) => (
                        <label
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: props.gapH,
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <input
                                type="checkbox"
                                name={option}
                                checked={(
                                    (Array.isArray(value) && value) ||
                                    []
                                ).includes(option)}
                                onChange={multiSelectOnChange}
                                onFocus={revalidate}
                                style={{
                                    margin: 0,
                                    cursor: "pointer",
                                }}
                            />
                            {option}
                        </label>
                    ))}
                </div>
            )
            break
        case "radio":
            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: props.gapV,
                        ...style,
                    }}
                >
                    {props.options.map((option, index) => (
                        <label
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: props.gapH,
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <input
                                type="radio"
                                name={option}
                                value={option}
                                checked={value === option}
                                onChange={onChangeEventTargetValue}
                                onFocus={revalidate}
                                style={{
                                    margin: 0,
                                    cursor: "pointer",
                                }}
                            />
                            {option}
                        </label>
                    ))}
                </div>
            )
            break
        case "number":
            const numberUpdateFormState = (event) => {
                const roundedValue =
                    Math.round(Number(event.target.value) / props.step) *
                    props.step
                const boundedValue = Math.min(
                    Math.max(roundedValue, props.min),
                    props.max
                )
                updateField("value", boundedValue)
            }

            const numberOnFocusLost = (event) => {
                numberUpdateFormState(event)
            }

            const numberOnKeyDown = (event) => {
                if (event.key === "Enter") {
                    numberUpdateFormState(event)
                }
            }

            elements.push(
                <input
                    type="number"
                    value={value}
                    onChange={onChangeEventTargetValue} // Handle input changes
                    onFocus={revalidate}
                    onKeyDown={numberOnKeyDown}
                    onBlur={numberOnFocusLost}
                    style={style}
                    placeholder={props.numberPlaceholder}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                />
            )
            break
        case "slider":
            const track = props.sliderTrack
            const handle = props.sliderHandle

            const handleCSS = `appearance: none;
                -webkit-appearance: none;
                width: ${handle.size}px;
                height: ${handle.size}px;
                border-radius: 10000px;
                background-color: ${handle.color};
                box-sizing: border-box;
                translate: 0 ${
                    (-handle.size + track.height) / 2 -
                    (track.border
                        ? Math.min(track.border.width, track.height / 2)
                        : 0)
                }px;
                border-width: ${handle.border?.width || 0}px;
                border-style: ${handle.border ? "solid" : "none"};
                border-color: ${handle.border?.color || "none"};`

            const trackCSS = `appearance: none;
                -webkit-appearance: none;
                height: ${track.height}px;
                border-radius: 10000px;
                background-color: ${track.color};
                box-sizing: border-box;
                border-width: ${track.border?.width || 0}px;
                border-style: ${track.border ? "solid" : "none"};
                border-color: ${track.border?.color || "none"};`

            elements.push(
                <div
                    // id={elementId}
                    style={{
                        display: "flex",
                        flexDirection:
                            props.sliderLabel?.position == "right"
                                ? "row-reverse"
                                : "row",
                        gap: gap,
                        alignItems: "center",
                        ...style,
                    }}
                >
                    {props.sliderLabel && (
                        <p style={{ margin: 0 }}>
                            {props.sliderLabel.prefix}
                            {value || props.sliderDefaultValue}
                            {props.sliderLabel.suffix}
                        </p>
                    )}
                    <input
                        type="range"
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        value={value || props.sliderDefaultValue}
                        onChange={onChangeEventTargetValue}
                        onFocus={revalidate}
                        style={{
                            flex: 1,
                            appearance: "none",
                            outline: "none",
                            margin: 0,
                            cursor: "pointer",
                            height: Math.max(handle.size, track.height),
                            background: "none",
                        }}
                    />
                </div>,
                <style>
                    {`#${elementId} input[type=range]::-webkit-slider-thumb {${handleCSS}}
                    #${elementId} input[type=range]::-moz-range-thumb {${handleCSS}}

                    #${elementId} input[type=range]::-webkit-slider-runnable-track {${trackCSS}}
                    #${elementId} input[type=range]::-moz-range-track {${trackCSS}}
                    #${elementId} input[type=range]::-moz-range-progress {${trackCSS}}
                    `}
                </style>
            )
            break
        case "nps":
            const { npsButtons, npsLabels } = props
            const { innerRadius, outerRadius } = npsButtons
            const buttonBorder = npsButtons.border

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: gap,
                        ...style,
                        width: undefined,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{npsLabels.left}</span>
                        <span>{npsLabels.right}</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: npsButtons.gap,
                        }}
                    >
                        {Array.from({ length: props.npsMax + 1 }).map(
                            (_, index) => {
                                const selected = value == index
                                const radius =
                                    index == 0
                                        ? `${outerRadius}px ${innerRadius}px ${innerRadius}px ${outerRadius}px`
                                        : index == props.npsMax
                                        ? `${innerRadius}px ${outerRadius}px ${outerRadius}px ${innerRadius}px`
                                        : `${innerRadius}px`

                                return (
                                    <motion.button
                                        key={index}
                                        animate={{
                                            backgroundColor: selected
                                                ? npsButtons.selectedColor
                                                : npsButtons.deselectedColor,
                                            color: selected
                                                ? npsButtons.selectedFontColor
                                                : npsButtons.deelectedFontColor,
                                        }}
                                        style={{
                                            position: "relative",
                                            flex: 1,
                                            height: npsButtons.height,
                                            border: "none",
                                            outline: "none",
                                            borderRadius: radius,
                                            minWidth: npsButtons.minWidth,
                                            cursor: "pointer",
                                            ...npsButtons.font,
                                        }}
                                        onClick={() => {
                                            updateField("value", index)
                                            revalidate()
                                        }}
                                        initial={false}
                                        transition={npsButtons.transition}
                                    >
                                        {index}
                                        {buttonBorder && (
                                            <motion.div
                                                animate={{
                                                    borderColor: selected
                                                        ? buttonBorder.selectedColor
                                                        : buttonBorder.deselectedColor,
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    borderWidth:
                                                        buttonBorder.widthIsMixed
                                                            ? `${buttonBorder.widthTop}px ${buttonBorder.widthRight}px ${buttonBorder.widthBottom}px ${buttonBorder.widthLeft}px`
                                                            : `${buttonBorder.width}px`,
                                                    borderStyle:
                                                        buttonBorder.style,
                                                    borderRadius: radius,
                                                    pointerEvents: "none",
                                                }}
                                                initial={false}
                                                transition={
                                                    npsButtons.transition
                                                }
                                            />
                                        )}
                                    </motion.button>
                                )
                            }
                        )}
                    </div>
                </div>
            )
            break
        case "date":
        case "time":
            elements.push(
                <input
                    // id={elementId}
                    type={type}
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={{
                        ...style,
                    }}
                />,
                <style>{`#${elementId} input::-webkit-datetime-edit {
                    flex: 0 1 auto;
                    z-index: 1;
                    cursor: text;
                    color: ${props.fontColor};
                }
                
                #${elementId} input::-webkit-calendar-picker-indicator {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    inset: 0;
                    padding: 0;
                    opacity: 0;
                    margin: 0;
                    overflow: visible;
                    cursor: pointer;
                }`}</style>
            )
            break
        case "email":
            function emailOnFocusLost(event) {
                if (
                    event.target.value.length > 0 &&
                    !isEmailAddress(event.target.value)
                ) {
                    invalidate()
                }
            }

            elements.push(
                <input
                    type="text"
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    onBlur={emailOnFocusLost}
                    style={style}
                    placeholder={props.emailPlaceholder}
                />
            )
            break
        case "phoneNumber":
            function phoneNumberOnChange(event) {
                updateField(
                    "value",
                    formatPhoneNumber(
                        event.target.value,
                        props.phoneNumberFormat,
                        props.phoneNumberCountryCodeFormat
                    )
                )
            }

            elements.push(
                <input
                    type="tel"
                    value={value}
                    onChange={phoneNumberOnChange}
                    onFocus={revalidate}
                    style={style}
                    placeholder={props.phoneNumberPlaceholder}
                />
            )
            break
    }

    return (
        <motion.div
            ref={ref}
            id={elementId}
            animate={{
                backgroundColor:
                    invalid && invalidStyle.backgroundColor
                        ? invalidStyle.backgroundColor
                        : props.backgroundColor,
            }}
            style={{
                position: "relative",
                display: "flex",
                color: props.fontColor,
                accentColor: props.accentColor,
                borderRadius: borderRadius,
                overflow: "auto",
                minHeight: minHeight,
                userSelect: props.textSelect ? "auto" : "none",
                ...props.font,
                ...props.style,
            }}
            initial={false}
            transition={invalidStyle.transition}
        >
            {icon && (
                <FormIcon
                    icon={icon}
                    style={{
                        position: "absolute",
                        top: `calc(50% - ${icon.size / 2}px)`,
                        left: iconOnLeft ? pl : undefined,
                        right: !iconOnLeft ? pr : undefined,
                    }}
                />
            )}
            {elements}
            {border && (
                <div
                    style={{
                        position: "absolute",
                        inset: 0,
                        borderWidth: border.widthIsMixed
                            ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                            : `${border.width}px`,
                        borderStyle: border.style,
                        borderColor: border.color,
                        borderRadius: borderRadius,
                        pointerEvents: "none",
                    }}
                />
            )}
            {invalidStyle.border && (
                <motion.div
                    animate={{
                        opacity: invalid ? 1 : 0,
                    }}
                    style={{
                        position: "absolute",
                        inset: 0,
                        borderWidth: invalidStyle.border.widthIsMixed
                            ? `${invalidStyle.border.widthTop}px ${invalidStyle.border.widthRight}px ${invalidStyle.border.widthBottom}px ${invalidStyle.border.widthLeft}px`
                            : `${invalidStyle.border.width}px`,
                        borderStyle: invalidStyle.border.style,
                        borderColor: invalidStyle.border.color,
                        borderRadius: borderRadius,
                        pointerEvents: "none",
                    }}
                    initial={false}
                    transition={invalidStyle.transition}
                />
            )}
            {PLACEHOLDER_FONT_COLOR_TYPES.includes(type) && (
                <style>{`#${elementId} input::placeholder { color: ${props.placeholderFontColor}; }`}</style>
            )}
        </motion.div>
    )
}

FormField.displayName = "Form Field"

addPropertyControls(FormField, {
    type: {
        type: ControlType.Enum,
        defaultValue: "input",
        options: [
            "input",
            "checkbox",
            "dropdown",
            "textArea",
            "email",
            "phoneNumber",
            "multiSelect",
            "radio",
            "number",
            "slider",
            "nps",
            "date",
            "time",
            "consent",
        ],
        optionTitles: [
            "Input",
            "Checkbox",
            "Dropdown",
            "Text Area",
            "Email",
            "Phone Number",
            "Multi-Select",
            "Radio",
            "Number",
            "Slider",
            "Net Promoter Score",
            "Date",
            "Time",
            "Consent",
        ],
    },
    name: {
        type: ControlType.String,
        defaultValue: "fieldName",
    },
    required: {
        type: ControlType.Boolean,
        defaultValue: false,
        hidden: (props) => NO_REQUIRED_TYPES.includes(props.type),
    },
    textPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "Type...",
        hidden: (props) => !TEXT_PLACEHOLDER_TYPES.includes(props.type),
    },
    numberPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "0",
        hidden: (props) => props.type != "number",
    },
    emailPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "hello@example.com",
        hidden: (props) => props.type != "email",
    },
    phoneNumberFormat: {
        title: "Format",
        type: ControlType.Enum,
        defaultValue: "(123) 456 - 7890",
        options: [
            "(123) 456 - 7890",
            "(123) 456-7890",
            "123-456-7890",
            "123.456.7890",
            "123 456 7890",
            "1234567890",
        ],
        hidden: (props) => props.type != "phoneNumber",
    },
    phoneNumberCountryCodeFormat: {
        title: "Country Code Format",
        type: ControlType.Enum,
        defaultValue: "+1",
        options: ["+1", "(1)", "1"],
        displaySegmentedControl: true,
        hidden: (props) => props.type != "phoneNumber",
    },
    phoneNumberPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "+1 (123) 456 - 7890",
        hidden: (props) => props.type != "phoneNumber",
    },
    consentText: {
        type: ControlType.String,
        defaultValue: "I agree to the Terms & Conditions.",
        title: "Text",
        hidden: (props) => props.type != "consent",
    },
    checkboxText: {
        type: ControlType.String,
        defaultValue: "Checkbox",
        title: "Text",
        hidden: (props) => props.type != "checkbox",
    },
    options: {
        title: "Options",
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.String,
        },
        defaultValue: ["Option 1", "Option 2", "Option 3"],
        hidden: (props) => !OPTIONS_TYPES.includes(props.type),
    },
    npsMax: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 1,
        step: 1,
        displayStepper: true,
        title: "Max",
        hidden: (props) => props.type != "nps",
    },
    npsLabels: {
        type: ControlType.Object,
        title: "Labels",
        controls: {
            left: {
                type: ControlType.String,
                defaultValue: "Not Likely",
            },
            right: {
                type: ControlType.String,
                defaultValue: "Extremely Likely",
            },
        },
        hidden: (props) => props.type != "nps",
    },
    npsButtons: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Buttons",
        controls: {
            selectedColor: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
                title: "Selected",
            },
            selectedFontColor: {
                type: ControlType.Color,
                defaultValue: "#FFFFFF",
                title: "Selected Font Color",
            },
            deselectedColor: {
                type: ControlType.Color,
                defaultValue: "#F0F0F0",
                title: "Deselected",
            },
            deselectedFontColor: {
                type: ControlType.Color,
                defaultValue: "#000000",
                title: "Deslected Font Color",
            },
            font: {
                type: "font",
                controls: "extended",
                defaultFontType: "sans-serif",
                defaultValue: {
                    fontSize: 12,
                    lineHeight: 1,
                },
            },
            innerRadius: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
                step: 1,
            },
            outerRadius: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 0,
                step: 1,
            },
            gap: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
                step: 1,
            },
            height: {
                type: ControlType.Number,
                defaultValue: 40,
                min: 0,
                step: 1,
            },
            minWidth: {
                type: ControlType.Number,
                defaultValue: 30,
                min: 0,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    selectedColor: {
                        type: ControlType.Color,
                        defaultValue: "#004CA8",
                        title: "Selected",
                    },
                    deselectedColor: {
                        type: ControlType.Color,
                        defaultValue: "#222222",
                        title: "Deselected",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 1,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1200,
                    damping: 70,
                },
            },
        },
        hidden: (props) => props.type != "nps",
    },
    sliderHandle: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Handle",
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
            },
            size: {
                type: ControlType.Number,
                defaultValue: 16,
                min: 1,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#0069E0",
                    },
                    width: {
                        type: ControlType.Number,
                        defaultValue: 1,
                        min: 0,
                        step: 1,
                    },
                },
            },
        },
        hidden: (props) => props.type != "slider",
    },
    sliderTrack: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Track",
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#D9D9D9",
            },
            height: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 1,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#0069E0",
                    },
                    width: {
                        type: ControlType.Number,
                        defaultValue: 1,
                        min: 0,
                        step: 1,
                    },
                },
            },
        },
        hidden: (props) => props.type != "slider",
    },
    min: {
        title: "Min",
        type: ControlType.Number,
        defaultValue: 0,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    max: {
        title: "Max",
        type: ControlType.Number,
        defaultValue: 100,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    step: {
        type: ControlType.Number,
        defaultValue: 1,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    resizeable: {
        type: ControlType.Boolean,
        defaultValue: true,
        hidden: (props) => props.type != "textArea",
    },
    textAreaDefaultHeight: {
        type: ControlType.Number,
        defaultValue: 100,
        min: 0,
        step: 1,
        title: "Height",
        hidden: (props) => props.type != "textArea" || !props.resizeable,
    },
    sliderDefaultValue: {
        type: ControlType.Number,
        defaultValue: 50,
        title: "Default Value",
        hidden: (props) => props.type != "slider",
    },
    sliderLabel: {
        type: ControlType.Object,
        defaultValue: {
            defaultValue: "left",
        },
        optional: true,
        controls: {
            position: {
                type: ControlType.Enum,
                defaultValue: "left",
                options: ["left", "right"],
                optionTitles: ["Left", "Right"],
                displaySegmentedControl: true,
            },
            prefix: {
                type: ControlType.String,
            },
            suffix: {
                type: ControlType.String,
            },
        },
        hidden: (props) => props.type != "slider",
    },
    dropdownDefaultValue: {
        type: ControlType.String,
        title: "Default Value",
        hidden: (props) => props.type != "dropdown",
    },
    dropdownNoneSelectedText: {
        type: ControlType.String,
        defaultValue: "Select an option",
        title: "None Selected Text",
        hidden: (props) =>
            props.options.includes(props.defaultValue) ||
            props.type != "dropdown",
    },
    backgroundColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
        optional: true,
        title: "Background",
    },
    accentColor: {
        title: "Accent",
        type: ControlType.Color,
        defaultValue: "#0075FF",
        hidden: (props) => !ACCENT_COLOR_TYPES.includes(props.type),
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#000",
        hidden: (props) =>
            props.type == "slider" ? props.sliderLabel != null : false,
    },
    placeholderFontColor: {
        type: ControlType.Color,
        defaultValue: "rgba(0,0,0,0.5)",
        hidden: (props) => !PLACEHOLDER_FONT_COLOR_TYPES.includes(props.type),
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontSize: 14,
            lineHeight: 1.5,
        },
        hidden: (props) =>
            props.type == "slider" ? props.sliderLabel != null : false,
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#222",
            },
            width: {
                type: ControlType.FusedNumber,
                defaultValue: 1,
                toggleKey: "widthIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "widthTop",
                    "widthRight",
                    "widthBottom",
                    "widthLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    },
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 16,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_TYPES.includes(props.type),
    },
    gapH: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_HV_TYPES.includes(props.type),
    },
    gapV: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_HV_TYPES.includes(props.type),
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 8,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    icon: iconPropertyControls,
    textSelect: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    invalidStyle: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            backgroundColor: {
                type: ControlType.Color,
                defaultValue: "#FFF5F5",
                optional: true,
                title: "Background",
            },
            border: {
                type: ControlType.Object,
                optional: true,
                defaultValue: {
                    color: "#FF0000",
                    width: 2,
                    style: "solid",
                },
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#FF0000",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 2,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1200,
                    damping: 70,
                },
            },
        },
    },
})

// Utility functions

function isEmailAddress(string) {
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,64}$/.test(string)
}

function formatPhoneNumber(phoneNumberString, format, countryCodeFormat) {
    // Remove all non-numeric characters
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")

    // Check the length to decide if there's a country code
    const match = cleaned.match(/^(\d{1,4})?(\d{3})(\d{3})(\d{4})$/)

    if (match) {
        let intlCode = ""
        if (match[1]) {
            switch (countryCodeFormat) {
                case "+1":
                    intlCode = `+${match[1]} `
                    break
                case "(1)":
                    intlCode = `(${match[1]}) `
                    break
                case "1":
                    intlCode = `${match[1]} `
                    break
            }
        }

        switch (format) {
            case "(123) 456 - 7890":
                return `${intlCode}(${match[2]}) ${match[3]} - ${match[4]}`
            case "(123) 456-7890":
                return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`
            case "123-456-7890":
                return `${intlCode}${match[2]}-${match[3]}-${match[4]}`
            case "123.456.7890":
                return `${intlCode}${match[2]}.${match[3]}.${match[4]}`
            case "123 456 7890":
                return `${intlCode}${match[2]} ${match[3]} ${match[4]}`
            case "1234567890":
                return `${intlCode}${match[2]}${match[3]}${match[4]}`
        }
    }

    // Return cleaned string if it's not a valid phone number
    return cleaned
}
